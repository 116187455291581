import React, { useEffect, useState } from 'react'
import banner from '../../images/banner.webp';
import AethosHome from '../../images/home/AethosHomenew.mp4';
import testimonialIcons from '../../images/home/testimonialIcons.png';
import rightArrow from '../../images/home/rightArrow.png';
import diagonalArrow from '../../images/home/diagonalArrow.png';
import arrow from '../../images/home/arrow.png';
import michaela from '../../images/home/michaela.png';
import paul from '../../images/home/paul.png';
import natasha from '../../images/home/natasha.png';
import backarrow from '../../images/home/backarrow.png';
import frontarrow from '../../images/home/frontarrow.png';

import '../styles/Home.css';

function HomeComponent() {

    const [text, setText] = useState("Hold you accountable.");

    useEffect(() => {
        const texts = ["Hold you accountable.", "Motivate and coach you.", "Help you win."];
        let currentIndex = 0;

        const interval = setInterval(() => {
            setText(texts[currentIndex]);
            currentIndex = (currentIndex + 1) % texts.length;
        }, 1500);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div style={{ color: 'black', paddingTop: '5vh' }}>
            <div className='wrapperContainer'>
                <div className='wrapper'>
                    <video muted autoPlay loop preload="auto" poster={banner}
                        style={{
                            width: "100%",
                            objectFit: "cover",
                        }} >
                        <source src={AethosHome} type="video/mp4" />
                    </video>
                </div>
                <div className="hero-texthome">
                    <h1 className="titlehome">
                        Move. <br />
                        Train.<br />
                        Optimize.
                    </h1>
                </div>
                <div className='boxContainer'>
                    <div className='boxitem' style={{ backgroundColor: '#F8f8F8', border: '1px #ECECEC solid' }}>
                        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                            <img src={testimonialIcons} width="137px" style={{ paddingRight: '2%' }} alt='Testimonial Icons' />
                            <div><span style={{ color: 'black', fontSize: 20, fontFamily: 'Rubik', fontWeight: '700' }}>83,825 +<br /></span><span style={{ color: '#9B9B9B', fontSize: 14, fontFamily: 'Rubik', fontWeight: '400' }}>Appointments</span></div>
                        </div>
                        <p style={{ fontSize: 14 }}>Working out at Aethos has been such a positive experience. They have a really welcoming ... <span style={{ color: '#236674', fontSize: 16, fontFamily: 'Karla', fontWeight: '200', textDecoration: 'underline' }}><a href='#testimonialId'>Read more</a></span></p>
                        <hr style={{ color: '#D6D6D6', marginBottom: 0 }} />
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <p style={{ color: '#A9A9A9', fontSize: 12 }}>Natasha Staniszewski</p>
                            <p style={{ color: '#A9A9A9', fontSize: 12 }}>Jan 23, 2022</p>
                        </div>
                    </div>
                    <div className='boxitem' style={{ background: 'linear-gradient(113deg, rgba(74.37, 74.37, 74.37, 0.69) 0%, rgba(30.81, 30.81, 30.81, 0.73) 0%, rgba(47, 48, 50, 0.81) 100%)', border: '0.50px #575757 solid', opacity: 0.98, backdropFilter: 'blur(40px)' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '24vh' }}>
                            <p style={{ color: '#ffffff', fontSize: 14, fontFamily: 'Rubik' }}>Move, Train & Optimize yourself into your best life possible.</p>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <p style={{ color: '#ffffff', fontSize: 14, fontFamily: 'Rubik', fontWeight: 700 }}>Schedule your consultation with one of our experts today.</p>
                                <a href="https://aethos.janeapp.com" target="_blank" rel="noopener noreferrer">
                                    <img style={{ width: '40px', height: '40px' }} src={rightArrow} alt='right arrow' />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='boxitem' style={{ backgroundColor: '#D8AB46', border: '1px #D8AB46 solid' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '24vh' }}>
                            <a href='/what-we-offer' style={{ alignSelf: 'end' }}>
                                <img style={{ width: '40px', height: '40px' }} src={diagonalArrow} alt='diagonal arrow' />
                            </a>
                            <p style={{ fontSize: 16, fontFamily: 'Rubik', fontWeight: 700, marginBottom: 0 }}>Browse Services</p>
                            <p style={{ fontSize: 14, fontFamily: 'Rubik' }}>We strive to transform and elevate your overall health and wellbeing through balancing the physical, mental, emotional, and social aspects of human experience.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='slogantext'>
                <span style={{ color: 'rgba(0, 0, 0, 0.80)', fontSize: 42, fontFamily: 'Karla', fontWeight: '800', textTransform: 'uppercase', letterSpacing: 2.10, wordWrap: 'break-word' }}>
                    Life is your sport, AETHOS helps you </span>
                <span style={{ color: '#D8AB46', fontSize: 42, fontFamily: 'Karla', fontWeight: '800', textTransform: 'uppercase', letterSpacing: 2.10, wordWrap: 'break-word' }}>
                    win.
                </span>
            </div>

            <div className='whatwedo'>
                <div className='note'>
                    <div id="carouselExampleControlsNoTouching" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <p style={{ fontFamily: 'Rubik', fontSize: 46, fontWeight: 400, wordWrap: 'break-word' }}>What We Do</p>
                                <p style={{ fontFamily: 'Karla', fontSize: 18, fontWeight: 400, color: '#BEBEBE' }}>We are a luxury one-stop shop for all your health needs. Our team of elite health professionals will curate a health plan to fit you. <br /> <br />
                                    Move, Train & Optimize yourself into your best life possible. Life is your sport. Aethos helps you win.</p>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                    <p style={{ paddingTop: '20%' }}>
                                        <a href='/what-is-aethos' style={{ fontFamily: 'Karla', color: 'white', textDecoration: 'underline' }}>
                                            Learn more about Aethos    <span><img src={arrow} style={{ height: '14px', width: '14px' }} alt='arrow' /></span>
                                        </a>
                                    </p>
                                    <p style={{ color: '#C5C5C5', fontSize: 14 }}>1/2</p>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <p style={{ fontFamily: 'Rubik', fontSize: 46, fontWeight: 400, wordWrap: 'break-word' }}>Our Philosophy</p>
                                    <p style={{ fontFamily: 'Karla', fontSize: 18, fontWeight: 400, color: '#BEBEBE' }}>At Aethos, we view our members as whole and dynamic individuals. We understand how various aspects of our lives impact our health and wellbeing and strive to tailor our methods to accommodate these challenges. <br /><br />
                                        We believe people are capable of achieving great things, and we are determined to show you the way. Our approach not only allows us to curate the best wellness plan for our members, but also empowers them to take responsibility to achieve and maintain an elevated standard of physical and cognitive performance. Our goal is to optimize overall health and performance, now and for the future.</p>
                                    <p style={{ color: '#C5C5C5', fontSize: 14, alignSelf: 'end' }}>2/2</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='sloganbg'>
                <div style={{ textAlign: 'center', color: '#172026', fontSize: 43, fontFamily: 'Rubik', fontWeight: '400', letterSpacing: 1.72 }}>ONE STOP FOR ALL YOUR HEALTH NEEDS</div>
                <div style={{ textAlign: 'center', color: '#323232', fontSize: 26, fontFamily: 'Karla', fontWeight: '400', wordWrap: 'break-word', paddingTop: '2%' }}>Our expert health professionals are here to</div>
                <div style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                    <div className='changingText' style={{ width: 548, textAlign: 'center', color: text === "Hold you accountable." ? '#000000' : text === "Motivate and coach you." ? '#366572' : '#D8AB46', fontSize: 40, fontFamily: 'Karla', fontWeight: '700', letterSpacing: 0.40, wordWrap: 'break-word' }}>{text}</div>
                </div>
            </div>

            <div id='testimonialId'>
                <div id="carouselExample" className="carousel slide">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '5% 8%', flexWrap: 'wrap' }}>
                                <div className='testimonialContainer'>
                                    <div>
                                        <div style={{ color: '#0A517A', fontSize: 40, fontFamily: 'Rubik', fontWeight: '400', wordWrap: 'break-word' }}>“Working out at Aethos has been such a positive experience.”</div>
                                        <div style={{ color: '#143142', fontSize: 16, fontFamily: 'Karla', fontWeight: '500', wordWrap: 'break-word' }}>“They have a really welcoming, knowledgeable, and professional team to help you face whatever fitness goal and challenges you have. The facility itself is awesome- there's lots of natural light and it's always really clean.”</div>
                                    </div>
                                    <div>
                                        <p style={{ color: '#071F2D', fontSize: 16, fontFamily: 'Karla', fontWeight: '700', letterSpacing: 0.80, wordWrap: 'break-word' }}>NATASHA STANISZEWSKI</p>
                                        <p style={{ color: '#575757', fontSize: 16, fontFamily: 'Karla', fontWeight: '400', wordWrap: 'break-word' }}>Canadian sports reporter</p>
                                    </div>
                                </div>
                                <img src={natasha} className='testimonialImg' alt="natasha" />
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '5% 8%', flexWrap: 'wrap' }}>
                                <div className='testimonialContainer'>
                                    <div>
                                        <div style={{ color: '#0A517A', fontSize: 40, fontFamily: 'Rubik', fontWeight: '400', wordWrap: 'break-word' }}>“Aethos is fabulous!”</div>
                                        <div style={{ color: '#143142', fontSize: 16, fontFamily: 'Karla', fontWeight: '500', wordWrap: 'break-word' }}>“I've been training regularly with Emma and appreciate her ablity to provide detailed feedbacks and corrections that make a difference a good session and a great one. I recommend unreservedly.”</div>
                                    </div>
                                    <div>
                                        <p style={{ color: '#071F2D', fontSize: 16, fontFamily: 'Karla', fontWeight: '700', letterSpacing: 0.80, wordWrap: 'break-word' }}>PAUL BARTER</p>
                                        <p style={{ color: '#575757', fontSize: 16, fontFamily: 'Karla', fontWeight: '400', wordWrap: 'break-word' }}>Tech Investor & Consultant</p>
                                    </div>
                                </div>
                                <img src={paul} className='testimonialImg' alt="paul" />
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '5% 8%', flexWrap: 'wrap' }}>
                                <div className='testimonialContainer'>
                                    <div>
                                        <div style={{ color: '#0A517A', fontSize: 40, fontFamily: 'Rubik', fontWeight: '400', wordWrap: 'break-word' }}>“I was blown away by the holistic approach of the Aethos Team.”</div>
                                        <div style={{ color: '#143142', fontSize: 16, fontFamily: 'Karla', fontWeight: '500', wordWrap: 'break-word' }}>“I have worked with many personal trainers and this is the first time I felt the training plan was extremely specific to my strengths and weaknesses. I have enormous respect for what the Aethos team is doing. I want to tell everyone about it!”</div>
                                    </div>
                                    <div>
                                        <p style={{ color: '#071F2D', fontSize: 16, fontFamily: 'Karla', fontWeight: '700', letterSpacing: 0.80, wordWrap: 'break-word' }}>MICHAELA BURNS</p>
                                        <p style={{ color: '#575757', fontSize: 16, fontFamily: 'Karla', fontWeight: '400', wordWrap: 'break-word' }}>Entrepreneur and Interior Designer <br /> (Owner Mint Decor)</p>
                                    </div>
                                </div>
                                <img src={michaela} className='testimonialImg' alt="michaela" />
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                        <img src={backarrow} alt='back arrow' />
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                        <img src={frontarrow} alt='front arrow' />
                    </button>
                </div>

            </div>

        </div>
    )
}

export default HomeComponent