import React from 'react'
import AethosIcon from '../images/AethosIcon.png';
import aethosLogo from '../images/aethosLogo.png';
import linkedin from '../images/linkedin.png';
import insta from '../images/insta.png';
import facebook from '../images/facebook.png';
import youtube from '../images/youtube.png';
import treadmillIcon from '../images/treadmillIcon.png'
import arrowBlack from '../images/arrowBlack.png'
import './styles/Footer.css';
import { Link } from 'react-router-dom';

function FooterComponent() {
    const today = new Date();

    return (
        <div style={{ paddingTop: '25%' }}>
            <div style={{ position: 'relative', backgroundColor: '#252525', display: 'flex', flexDirection: 'column', padding: '15% 5% 5% 5%', rowGap: '50px' }}>
                <div className='requestBox'>
                    <div className='requestBoxTextContainer'>
                        <p style={{ color: '#2E2E2E', fontSize: 36, fontFamily: 'Rubik', fontWeight: '300', letterSpacing: 0.54 }}>ARE YOU READY TO <span style={{ fontWeight: '500', wordWrap: 'break-word' }}>GET STARTED?</span></p>
                        <p style={{ color: '#7A7A7A', fontFamily: 'Karla', fontSize: 16 }}>Request an appointment today and get a consultation from one of our experts.</p>
                        <a style={{ background: '#D8AB46', borderRadius: 24, display: 'flex', alignItems: 'center', maxWidth: '240px', justifyContent: 'center' }} className="btn" href='/request-appointment'>
                            Request appointment
                            <img src={arrowBlack} alt='arrow black' width="18px" height="14px" style={{ marginLeft: '5%' }} />
                        </a>
                    </div>
                    <img src={treadmillIcon} alt='treadmill icon' width="241px"></img>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                    <div className='addresscolumn' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <img src={AethosIcon} width="20%" style={{ marginRight: '3%' }} alt='aethos icon'></img>
                            <img src={aethosLogo} width="40%" alt='aethos logo'></img>
                        </div>
                        <div className='addressBox' >
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <p style={{ color: '#BC9437' }}>Address</p>
                                <p style={{ color: '#ECECEC' }}><nobr>41 Scarsdale Rd, Unit 2, Toronto</nobr></p>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <p style={{ color: '#BC9437' }}>Call</p>
                                <p style={{ color: '#ECECEC' }}>+1 416-546-1511</p>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <p style={{ color: '#BC9437' }}>Mail</p>
                                <p style={{ color: '#ECECEC' }}>team@aethos.ca</p>
                            </div>
                        </div>
                    </div>
                    <div className='footerlinks'>
                        <Link className="footerLinkItemBold" to="/what-is-aethos">
                            What is Aethos
                        </Link>
                        <Link className="footerLinkItemBold" to="/what-we-offer">
                            What we offer
                        </Link>
                        <Link className="footerLinkItemBold" to="/team">
                            The team
                        </Link>
                        <Link className="footerLinkItemBold" to="/contact-us">
                            Contact Us
                        </Link>
                    </div>
                    <div className='footerlinks'>
                        <p style={{ fontWeight: 800, marginBottom: 0 }}>Forms</p>
                        <Link className="footerLinkItem" to="/request-appointment">
                            Request appointment
                        </Link>
                        <Link className="footerLinkItem" to="https://aethos.janeapp.com" target='_blank'>
                            Book appointment
                        </Link>
                        <Link></Link>
                    </div>
                    <div className='footerlinks'>
                        <p style={{ fontWeight: 800, marginBottom: 0 }}>Resources</p>
                        <Link className="footerLinkItem" to="contact-us">
                            Partner with us
                        </Link>
                        <Link className="footerLinkItem" to="/health-safety">
                            Health & Safety
                        </Link>
                        <Link className="footerLinkItem" to="#Blogs">
                            Blogs
                        </Link>
                    </div>
                    <div className='footerlinks'>
                        <p style={{ fontWeight: 800 }}></p>
                        <Link className="footerLinkItem" to="/terms-of-service">
                            Terms of Service
                        </Link>
                        <Link className="footerLinkItem" to="/privacy-policy">
                            Privacy Policy
                        </Link>
                        <Link className="footerLinkItem" to="contact-us">
                            Careers: Join Aethos
                        </Link>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className='py-md-1 py-3' style={{ minWidth: '230px' }}>
                        <form action="https://aethos.us20.list-manage.com/subscribe/post?u=acfcbf105fef9ce675c593c8a&amp;id=301cd63e7a" method="post" id="form3" name="mc-embedded-subscribe-form" target="_blank" >
                            <label style={{ color: 'white', fontFamily: 'Karla', fontSize: 14 }} for="mce-EMAIL"> Join Our Mailing List </label><br></br>
                            <div style={{ display: 'flex' }}>
                                <input className="emailInput" type="email" name="EMAIL" id="mce-EMAIL" placeholder="Enter your Email ID*" required />
                                <input className="joinButton" type="submit" value="Join" name="subscribe" id="mc-embedded-subscribe" />
                            </div>
                        </form>
                    </div>
                    <div className='py-md-1 py-3 mx-3' style={{ display: 'flex' }}>
                        <a href="https://www.linkedin.com/company/aethos-human-performance-company/" target='_blank' rel="noreferrer" style={{ marginRight: '5%' }} >
                            <img src={linkedin} width="25px" height="25px" alt='linkedin' />
                        </a>
                        <a href="https://www.instagram.com/teamaethos/" target='_blank' rel="noreferrer" style={{ marginRight: '5%' }}>
                            <img src={insta} width="25px" height="25px" alt='instagram' />
                        </a>
                        <a href="https://www.facebook.com/teamaethos/" target='_blank' rel="noreferrer" style={{ marginRight: '5%' }}>
                            <img src={facebook} width="25px" height="25px" alt='facebook' />
                        </a>
                        <a href="https://www.youtube.com/channel/UC_0zk93cVVaH5UFiVmfBswQ" target='_blank' rel="noreferrer" style={{ marginRight: '5%' }}>
                            <img src={youtube} width="25px" height="25px" alt='youtube' />
                        </a>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: '#D8AB46' }}>
                <p style={{ color: 'white', fontFamily: 'Karla', fontSize: 14, textAlign: 'center', marginBottom: 0 }}>© Aethos {today.getFullYear()}. All rights reserved.</p>
            </div>
        </div>
    )
}

export default FooterComponent