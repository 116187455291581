import React from 'react'
import { NavLink } from "react-router-dom";
import './styles/Navbar.css'

function NavbarComponent() {
    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary fixed-top">
            <div className="container-fluid">
                <a className="navbar-brand" href="/" style={{ fontFamily: 'Rubik', color: '#BC9437', fontWeight: 500, fontSize: '24px', letterSpacing: 6.48, padding: '0 0 0 10%' }}>AETHOS</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mb-2 mb-lg-0" style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%', fontFamily: 'Rubik' }}>
                        <li className="nav-item"></li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to='/what-is-aethos'>What is Aethos</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to='/what-we-offer'>What we offer</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to='/team'>The Team</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to='/contact-us'>Contact Us</NavLink>
                        </li>
                        <a className="btn btn-primary" style={{ backgroundColor: '#236674', border: 'none' }} href="https://aethos.janeapp.com" target="_blank" rel="noreferrer">
                            Book an appointment
                        </a>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default NavbarComponent